@keyframes entrance {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes exit {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

::view-transition-old(root) {
    animation: exit 1s ease 0s 1 normal forwards;
}

::view-transition-new(root) {
    animation: entrance 1s ease 0s 1 normal forwards;
}
